// ENV_URL definido no webpack.config
// const ENV = ENV_URL;
// const ENV = 'prod'; // força produção pra teste de dados tempo real
//const ENV = 'dev';

const getURI = (api) => {
   
//    console.log('URL');
//    console.log (process.env.REACT_APP_AMBIENTE);
//    console.log(window.location.href);
//   console.log(window.location.host);
    
    // se rodar com npm start
    // console.log(process.env.NODE_ENV);
    if (process.env.NODE_ENV == 'development')  
        return 'http://localhost:2400';

    //else if (process.env.NODE_ENV == 'production')
    let prefix = window.location.host.startsWith("localhost") ? 'http://' : 'https://';
    //console.log(prefix);
    return (prefix + window.location.host);

    //if (process.env.REACT_APP_AMBIENTE)
    //   return process.env.REACT_APP_AMBIENTE;
	// else		
    //  return 'http://localhost:2400';
}

const endpointsConfig = {
    login:          `${getURI('login')}/api/v1/login`,
    user:           `${getURI('user')}/api/v1/user`,
    role:           `${getURI('role')}/api/v1/role`,
    configHotel:    `${getURI('configHotel')}/api/v1/configHotel`,
    configJson:     `${getURI('configJson')}/api/v1/configJson`,
    histInitCards:      `${getURI('histInitCards')}/api/v1/histInitCards`,
    publicInfo:           `${getURI('publicInfo')}/api/v1/publicInfo`,
    
    util:           `${getURI('util')}/api/v1/util`,
}

export default endpointsConfig;
